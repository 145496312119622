@font-face {
    font-family: 'IntroCond';
    src: url('../../public/fonts/IntroCond.otf');
}

#stasis-valley-title {
    font-family: "IntroCond";
    word-spacing: 0.325em;
    letter-spacing: 0.1em;
    font-size: 9.5vh;
    color: whitesmoke;
}

#background-isolated {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 90vh;
    background-image: url("../../public/img/stasis-valley/bg-isolated.png");
}

#subject-isolated {
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 90vh;
    background-image: url("../../public/img/stasis-valley/subject-isolated.png");
}

#foreground-isolated {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 90vh;
    background-image: linear-gradient(to bottom, rgba(20, 20, 20, 0.25) 50%, rgba(20, 20, 20, 1)), url("../../public/img/stasis-valley/fg-isolated.png");
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

#content-background {
    background-color: rgba(20, 20, 20, 1);
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 32px;
    padding-right: 3lh;
    justify-content: safe;
}

#embed-trailer {
    width: 50vh;
    aspect-ratio: 16 / 9;
}