html {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body {
  background-color: whitesmoke;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

h1 {
  font-size: 4vh;
}

p {
  font-size: 2.5vh;
}

:root {
  --header-footer-color: rgb(17, 17, 18);
}

@font-face {
  font-family: 'IntroCond';
  src: url('../../public/fonts/IntroCond.otf');
}

@font-face {
  font-family: 'FFFForward';
  src: url('../../public/fonts/FFFForward.ttf')
}

.navbar {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  height: 8vh;
  background-color: var(--header-footer-color);
  padding-left: 12px;
}

.navbar-text {
  color: whitesmoke;
  font-size: 5vh;
  text-wrap: nowrap;
  text-decoration: none;
  font-family: "Calibri", sans-serif
}

.game-preview-background {
  display: flex;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  justify-content: left;
  align-items: center;
  padding: 12px;
}

.text-container {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-left: 8px;
}

.game-title-text {
  color: whitesmoke;
  margin-bottom: 16px;
}

.game-tagline-text {
  color: whitesmoke;
  font-size: 3.25vh;
  margin-bottom: 16px;
}

@media only screen and (max-device-width: 1366px) {
  .game-preview-background {
    background-attachment: scroll;
  }
}

.footer {
  display: flex;
  flex-direction: column;
  bottom: 0;
  height: 17.5vh;
  background-color: var(--header-footer-color);
  padding: 12px;
  justify-content: center;
  align-items: center;
}

#footer-button-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.footer-button-bg {
  height: 8vh;
  aspect-ratio: 1 / 1;
  background-color: whitesmoke;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 8px;
  margin-right: 8px;
}

.footer-button-icon {
  height: 75%;
  aspect-ratio: 1 / 1;
}

.footer-text {
  color: whitesmoke;
  font-size: 2.5vh;
}

.link-button {
  display: flex;
  width: fit-content;
  height: fit-content;
  background-color: whitesmoke;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  margin-top: 4vh;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
}

.button-text {
  color: var(--header-footer-color);
  font-size: 2.75vh;
  text-decoration: none;
}

.header-light {
  color: whitesmoke;
  font-size: 6vh;
}

.text-light {
  color: whitesmoke;
  font-size: 2.75vh;
  margin-top: 5vh;
}

.header-dark {
  color: var(--header-footer-color);
  font-size: 6vh;
}

.text-dark {
  color: var(--header-footer-color);
  font-size: 2.75vh;
  margin-top: 5vh;
}